<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12>
      <v-card>
        <TitleCard
          title="Direcciones"
          :subtitle="`${buyer.address.length} en su libreta de direcciones`"
        >
        </TitleCard>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="buyer.address"
          class="elevation-0 order-table"
          color="secondary"
          :disable-initial-sort="true"
          :pagination.sync="pagination"
          :rows-per-page-items="rowsPerPage"
          no-data-text="No hay address ingresados"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td v-html="props.item.nombre"></td>
              <td v-html="`${props.item.calle} ${props.item.numero}`"></td>
              <td v-html="props.item.comuna"></td>
              <td>
                <v-icon
                  v-if="user.role.id === 1 || user.role.id === 2"
                  small
                  class="mr-2"
                  @click="editItem(props.item)"
                >
                  far fa-edit
                </v-icon>
                <v-icon
                  v-if="
                    (user.role.id === 1 || user.role.id === 2) &&
                      user.shop.admin
                  "
                  small
                  @click="deleteItemAction(props.item)"
                >
                  delete
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
    <v-dialog v-model="dialogEdit" width="600" persistent>
      <v-card>
        <TitleDialog
          :title="'Editar direccion'"
          close
          :close-action="
            () => {
              dialogEdit = false
            }
          "
        ></TitleDialog>
        <v-card-text class="pa-0">
          <v-container grid-list-lg>
            <v-layout wrap>
              <v-flex xs12 sm6>
                <v-select
                  v-model="item.tipo"
                  :items="addressType"
                  item-text="name"
                  item-value="id"
                  label="Tipo de direccion"
                  hide-details
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6>
                <v-select
                  v-model="item.comunaID"
                  :items="communes"
                  item-text="name"
                  item-value="id"
                  label="Comuna"
                  hide-details
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm12>
                <v-text-field v-model="item.nombre" label="Nombre" hide-details>
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm8>
                <v-text-field v-model="item.calle" label="Calle" hide-details>
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm4>
                <v-text-field v-model="item.numero" label="Numero" hide-details>
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm12>
                <v-text-field
                  v-model="item.telefono"
                  label="Telefono"
                  hide-details
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm12>
                <v-text-field
                  v-model="item.campo_random"
                  label="Mas informacion"
                  hide-details
                >
                </v-text-field>
              </v-flex>
              <v-flex v-if="item.tipo === 'CrClinica'" xs12>
                <v-text-field
                  v-model="item.tipo1"
                  label="Tipo de entrega"
                  hide-details
                >
                </v-text-field>
              </v-flex>
              <v-flex class="pb-0" xs12>
                <v-subheader>Buscar en mapa</v-subheader>
                <GmapAutocomplete
                  ref="search-map"
                  class="input-search"
                  style="width:100%;"
                  :country="['cl']"
                  :value="addressComplete"
                  @place_changed="setPlace"
                >
                </GmapAutocomplete>
              </v-flex>
              <v-flex xs12>
                <gmap-map
                  id="map"
                  ref="myMap"
                  :center="center"
                  :zoom="zoom"
                  :options="options"
                  :style="`width:100%;height:${windowHeight}px;`"
                >
                  <GmapMarker
                    :position="latLngOrder"
                    :clickable="false"
                    :draggable="true"
                    @dragend="dragend"
                  />
                </gmap-map>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat color="back" depressed @click="dialogEdit = false">
            Volver
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="accent" depressed @click="editAction">
            Listo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn fixed dark fab bottom right color="accent" @click="editItem({})">
      <v-icon>add</v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import { CLIENT } from '../../config'
import TitleCard from '../useful/titleCard.vue'
import TitleDialog from '../useful/titleDialog.vue'

export default {
  name: 'BuyerAddress',
  components: { TitleCard, TitleDialog },
  props: ['buyer', 'getBuyer'],
  data: () => ({
    addressType: [
      { id: 'DrParticular', name: 'Dirección particular' },
      { id: 'DrEmpresa', name: 'Empresa o institución' },
      { id: 'CrClinica', name: 'Clínica u hospital' },
      { id: 'CrVelatorio', name: 'Iglesia o Velatorio' },
      { id: 'CrRetiro', name: 'Retiro en Florería Rosalinda (sin reparto)' }
    ],
    headers: [
      { text: 'Nombre destinatario', align: 'left', sortable: false },
      { text: 'Direccion', align: 'left', sortable: false },
      { text: 'Comuna', align: 'left', sortable: false },
      { text: '', align: 'left', sortable: false }
    ],
    pagination: { rowsPerPage: 50 },
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
    dialogEdit: false,
    item: {},
    options: {
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false
    },
    zoom: 16,
    center: { lat: -33.4262, lng: -70.6429 },
    latLngOrder: {},
    windowHeight: 250
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    addressComplete() {
      if (this.item.calle !== undefined && this.item.comuna !== undefined) {
        return `${this.item.calle} ${this.item.numero}, ${
          this.item.comuna.name
        }, Chile`
      }
      return ''
    },
    communes() {
      return this.$store.getters.getCommunes
    }
  },
  methods: {
    async editAction() {
      try {
        const url = this.item.id
          ? `${CLIENT}/${this.buyer.id}/address/${this.item.id}`
          : `${CLIENT}/${this.buyer.id}/address`
        await this.$http({
          method: this.item.id ? 'put' : 'post',
          url: url,
          data: {
            item: this.item
          }
        })
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Actualizado'
        })
        this.getBuyer()
        this.dialogEdit = false
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async deleteItem(item) {
      try {
        await this.$http.delete(`${CLIENT}/${this.buyer.id}/address/${item.id}`)
        this.$store.dispatch('setConfirm', { active: false })
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Eliminado'
        })
        this.getBuyer()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    changeComuna(e) {
      this.item.comuna.id = e
    },
    setLatLngOrder(position) {
      this.latLngOrder = position
      this.center = position
      this.zoom = 16
      this.item.latitude = position.lat
      this.item.longitude = position.lng
    },
    dragend(e) {
      const position = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }
      this.setLatLngOrder(position)
    },
    editItem(item) {
      this.item = item
      this.setLatLngOrder({
        lat: item.latitude,
        lng: item.longitude
      })
      this.dialogEdit = true
    },
    deleteItemAction(item) {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Eliminar direccion?',
        description: 'Se eliminara del sistema, no se puede recuperar',
        action: () => this.deleteItem(item)
      })
    },
    setPlace(e) {
      const position = {
        lat: e.geometry.location.lat(),
        lng: e.geometry.location.lng()
      }
      this.setLatLngOrder(position)
    }
  }
}
</script>

<style></style>
